import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <a href={"https://voyeurhouse.com/media#/models"}><h2>Gallery</h2></a>
          <a href={"https://blog.voyeurhouse.com/"}>
            <p>
              Ready for the Full Voyeur House Experience?
            </p>
          </a>
        </div>
        <div>
          <h3 className="h4 mt-4 mb-2 text-black">Welcome to Our Unique Live
            RealLifeCam World!</h3>
          <p className="mb-4 text-black">
            Explore our unique <a className="underline"
                                  style={{color: '#000'}}
                                  href="https://voyeurhouse.com/locations"> voyeur
            houses</a>, where you get
            a real-life view into the daily lifes of <span
            className="font-bold"> real people </span>.
            Our site is more than just another <a className="underline"
                                                  style={{color: '#000'}}
                                                  href="https://reallifecam.house/">reallifecam </a>
            destination. It's a window into genuine, unscripted moments,
            offering a look at real life as it unfolds in all it’s
            honesty and nudity.
          </p>

          <h3 className="h4 mb-2 text-black">Hidden Cams, Spy Cams – See
            the Unseen!</h3>

          <p className="mb-4 text-black">
            Our platform goes beyond what you know from <span
            className="font-bold">camming and
                    coming</span>. We offer free <span className="font-bold">hidden cams</span> and <span
            className="font-bold">spy cams</span> that
            provide an authentic glimpse into the <a
            className="underline"
            style={{color: '#000'}}
            href="https://voyeurhouse.com/locations">real lifes of real
            people</a>. It's like
            being a secret observer in someone's world, witnessing their
            true stories and sexual moments as they happen.
          </p>

          <h3 className="h4 mb-2 text-black">Real Cams, Real People, Real
            Life</h3>

          <p className="mb-4 text-black">
            Joining us means embarking on an exciting journey. Our free <a
            className="underline"
            style={{color: '#000'}}
            href="https://voyeurhouse.com/live">live
            cams</a> connect you to the
            real-world experiences of people, giving you a front-row
            seat to their daily adventures and nude moments. It's a
            unique opportunity to see life and people from a new, nude
            perspective.
          </p>

          <h3 className="h4 mb-2 text-black">Discover the Thrill of True
            Life with Premium
            Membership</h3>

          <p className="mb-2 text-black">
            After diving into our world of <span className="font-bold">free voyeur cams</span> and live
            experiences, why not enhance your journey with Premium
            Membership?
          </p>
          <p className="mb-2 text-black">
            Here's what you get when you upgrade:
          </p>
          <ul className="mb-2 text-black">
            <li className="list-disc mt-1"><span className="font-bold">24/7 Replays</span>: <a
              className="underline"
              style={{color: '#000'}}
              href="https://voyeurhouse.com/replay">Relive any
              moment</a>, anytime. Never
              miss out on the action.
            </li>
            <li className="list-disc mt-1"><span className="font-bold">Timeline Playback</span>: Easily navigate to the most
              interesting parts of the day.
            </li>
            <li className="list-disc mt-1"><span className="font-bold">Archive Access</span>: Explore past events and uncover hidden
              gems in our infinite <a
                className="underline"
                style={{color: '#000'}}
                href="https://voyeurhouse.com/media">archives</a>.
            </li>
            <li className="list-disc mt-1"><span className="font-bold">Creator Chat</span>: Directly <a
              className="underline"
              style={{color: '#000'}}
              href="https://voyeurhouse.com/locations">interact with the
              people</a> you're
              watching and get to know them better.
            </li>
          </ul>

          <p className="mb-4 text-black">
            Plus, exclusive features like <span className="font-bold">Financial Domination</span> and
            <span className="font-bold"> Motion Detection</span> await, adding an extra layer of
            excitement to your voyeur experience!
          </p>
          <h3 className="h4 mb-2 text-black">Ready for the Full Voyeur
            House Experience?</h3>

          <p className="mb-4 text-black">By subscribing to our Premium
            Membership, you unlock a world
            of enhanced features, nude voyeurism and deeper connections.
            See life through a clearer lens and become part of a
            community that values the raw beauty of reality.
          </p>
          <h4 className="h5 mb-2 text-black">Register Now – Your Ultimate
            Peek through the Keyhole into
            the Real Life Awaits!</h4>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                <a href={"https://voyeurhouse.com"}>
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                </a>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
